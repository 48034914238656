import React, { useState } from 'react';
import { Link } from 'gatsby';
import SolutionsDropdown from './SolutionsDropdown';
import styled from '@emotion/styled';

import Button from '../../ui/BaseButton';
import { COLORS } from '../../ui/variables';
import { ReactComponent as HalfArrowDownIcon } from '../../images/icons/half-arrow-down.svg';

const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
  height: 100%;
`;

const JoinBtn = styled(Button)`
  padding: 0 30px;
  height: 48px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const MainLinks = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

const NavLink = styled(Link)`
  transition: color 200ms ease;
  color: ${COLORS.BLACK};
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.5px;

  &:hover {
    color: ${COLORS.DARK_GRAY};
  }
`;

const PaddedLink = styled(NavLink)`
  margin: 0 20px;
  @media (max-width: 1320px) {
    margin: 0 16px;
  }
`;

const ServicesLink = styled(NavLink)`
  margin: 0 20px;
  @media (max-width: 1320px) {
    margin: 0 16px;
  }
`;

const ServicesLinkIco = styled(HalfArrowDownIcon)`
  transition: fill 200ms ease;
  margin-left: 8px;
  transform: translateY(-1px);

  ${ServicesLink}:hover & {
    fill: ${COLORS.DARK_GRAY};
  }
`;

const DropDownHoverWrap = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

interface Props {
  className?: string;
}

const Navigation = (props: Props) => {
  const [showServices, setShowServices] = useState(false);

  return (
    <Container className={props.className}>
      <MainLinks>
        <PaddedLink type='internal' to='/membership'>
          Membership
        </PaddedLink>

        <DropDownHoverWrap
          onMouseLeave={() => {
            setShowServices(false);
          }}
        >
          <ServicesLink
            type='internal'
            to='/services'
            onMouseEnter={() => {
              setShowServices(true);
            }}
          >
            Services&nbsp;
            <ServicesLinkIco />
          </ServicesLink>
          <SolutionsDropdown display={showServices} />
        </DropDownHoverWrap>

        <PaddedLink type='internal' to='/locations'>
          Locations
        </PaddedLink>
        <PaddedLink type='internal' to='/about-us'>
          About&nbsp;Us
        </PaddedLink>
        <PaddedLink type='internal' to='/careers'>
          Careers
        </PaddedLink>
      </MainLinks>

      <Actions>
        <PaddedLink type='internal' to='/locations'>
          Availability by Location
        </PaddedLink>
        <JoinBtn
          type='external'
          href={`${process.env.GATSBY_NEW_ONBOARDING_URL}signup`}
          color={COLORS.NEW_ORANGE}
        >
          Book Now
        </JoinBtn>
      </Actions>
    </Container>
  );
};

export default Navigation;
