import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import Button from '../../ui/BaseButton';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import { NAV_HEIGHTS } from './types';
import DogHumanGraphic from '../../images/dog-human-hugging-drawing.png';
import { ReactComponent as HamburgerIcon } from '../../images/icons/hamburger.svg';
import { ReactComponent as CloseIcon } from '../../images/icons/close-x.svg';
import getPathname from '../../utils/getPathname';
import { ToggleContext } from './mobileNavProvider';

const MobileNavContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px;
  font-weight: 600;
  height: 100%;
`;

const JoinBtn = styled(Button)`
  border-radius: 30px;
`;

const HeaderJoin = styled(JoinBtn)`
  padding: 0 24px;
  height: 40px;
  font-size: 16px;
`;

const MenuJoin = styled(JoinBtn)`
  padding: 0 30px;
  height: 48px;
  font-size: 18px;
  margin-left: 10px;
`;

const Menu = styled.nav<{ isActive: boolean }>`
  position: fixed;
  display: ${props => (props.isActive ? 'flex' : 'none')};
  justify-content: space-between;
  flex-direction: column;
  top: ${NAV_HEIGHTS.TABLET};
  left: 0;
  right: 0;
  height: calc(100% - ${NAV_HEIGHTS.TABLET});

  border-top: 2px solid ${COLORS.LIGHT_GRAY};
  background: ${COLORS.WHITE};
  background-image: url(${DogHumanGraphic});
  background-size: 280px;
  background-repeat: no-repeat;
  background-position: 100% 50px;

  @media ${BREAKPOINTS.MOBILE} {
    background-size: 180px;
    background-position: calc(100% + 20px) 50px;
  }
`;

const Links = styled.ul`
  list-style: none;
  margin: 0;
  padding: 68px 80px;

  @media ${BREAKPOINTS.MOBILE} {
    padding: 40px 20px;
  }

  li {
    margin-bottom: 68px;

    @media ${BREAKPOINTS.MOBILE} {
      margin-bottom: 32px;
    }
  }
`;

const MainLink = styled(Link)`
  font-size: 48px;
  text-decoration: none;
  color: ${COLORS.BLACK};
  font-weight: 400;

  @media ${BREAKPOINTS.MOBILE} {
    font-size: 24px;
  }
`;

const NavLink = styled(Link)`
  transition: color 200ms ease;
  color: ${COLORS.BLACK};
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.5px;
  margin-right: 24px;
  position: relative;
  top: 2px;

  &:hover {
    color: ${COLORS.DARK_GRAY};
  }

  @media (max-width: 555px) {
    max-width: 95px;
    top: 0px;
    margin-right: 12px;
    text-align: center;
  }

  @media (max-width: 430px) {
    font-size: 12px;
    max-width: 80px;
    margin-right: 8px;
  }

  @media (max-width: 375px) {
    font-size: 10px;
    max-width: 68px;
    margin-right: 6px;
  }
`;

const MenuFooter = styled.div`
  display: flex;
  padding-bottom: 32px;
  justify-content: center;
`;

const OpenMenu = styled.button`
  position: flex;
  background: transparent;
  padding: 0;
  width: 40px;
  height: 20px;
  border: 0;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: 24px;
  justify-content: center;

  @media (max-width: 555px) {
    margin-left: 12px;
  }
  @media (max-width: 430px) {
    margin-left: 8px;
  }
`;

const OpenMenuIcon = styled(HamburgerIcon)`
  ${OpenMenu}:hover & {
    fill: ${COLORS.DARK_GRAY};
  }
`;

const CloseMenuIcon = styled(CloseIcon)`
  ${OpenMenu}:hover & {
    fill: ${COLORS.DARK_GRAY};
  }
`;

interface Props {
  className?: string;
}

const MobileNavigation = (props: Props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { isMenuOpen, setIsMenuOpen } = useContext(ToggleContext);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const urlPathname = getPathname();

  setIsMenuOpen(menuOpen);

  return (
    <MobileNavContainer className={props.className}>
      <NavLink type='internal' to='/locations'>
        Availability by&nbsp;Location
      </NavLink>
      <HeaderJoin
        type='external'
        href={`${process.env.GATSBY_NEW_ONBOARDING_URL}signup`}
        color={COLORS.NEW_ORANGE}
      >
        Book
      </HeaderJoin>
      <OpenMenu onClick={toggleMenu} aria-label='Menu button'>
        {!menuOpen && <OpenMenuIcon />}
        {menuOpen && <CloseMenuIcon />}
      </OpenMenu>

      <Menu isActive={menuOpen}>
        <Links>
          <li>
            <MainLink to='/membership'>Membership</MainLink>
          </li>
          <li>
            <MainLink to='/services'>Services</MainLink>
          </li>
          <li>
            <MainLink to='/locations'>Locations</MainLink>
          </li>
          <li>
            <MainLink to='/about-us'>About Us</MainLink>
          </li>
          <li>
            <MainLink to='/careers'>Careers</MainLink>
          </li>
        </Links>

        <MenuFooter>
          <MenuJoin
            type='external'
            href={`${process.env.GATSBY_NEW_ONBOARDING_URL}signup`}
            color={COLORS.NEW_ORANGE}
          >
            Book Now
          </MenuJoin>
        </MenuFooter>
      </Menu>
    </MobileNavContainer>
  );
};

export default MobileNavigation;
