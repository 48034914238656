import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { css } from '@emotion/core';

import { BREAKPOINTS, COLORS } from '../../ui/variables';
import SmallDoorVetLogo from '../../images/small-door-vet-logo.svg';
import Navigation from './Navigation';
import MobileNavigation from './MobileNavigation';
import { NAV_HEIGHTS } from './types';
import { saveParamsToCookie } from '../../utils/captureUrlParams';

const Container = styled.header<{
  shadow: boolean;
  sticky: boolean;
}>`
  position: ${props => (props.sticky ? 'sticky' : 'inherit')};
  height: ${NAV_HEIGHTS.DESKTOP};
  padding: 0 70px;
  background: ${COLORS.WHITE};
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  box-shadow: ${props =>
    props.shadow ? '0px 2px 8px rgba(0, 0, 0, 0.16)' : 'none'};
  transition: box-shadow 200ms ease;

  @media ${BREAKPOINTS.TABLET} {
    height: ${NAV_HEIGHTS.TABLET};
    padding: 0 40px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    padding: 0 20px;
  }
`;

const Logo = styled(Link)`
  display: block;
  background: url(${SmallDoorVetLogo});
  background-repeat: no-repeat;
  width: 160px;
  height: 46px;
  flex-shrink: 0;
  margin-right: 48px;

  @media (max-width: 1270px) {
    margin-right: 40px;
  }

  @media ${BREAKPOINTS.TABLET} {
    background-size: 120px;
    width: 120px;
    height: 32px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    background-size: 110px;
    width: 110px;
    height: 29px;
  }

  @media (max-width: 430px) {
    background-size: 100px;
    width: 100px;
    margin-right: 32px;
  }

  @media (max-width: 375px) {
    background-size: 100px;
    width: 100px;
    margin-right: 30px;
  }
`;

const DesktopNavigation = styled(Navigation)`
  display: none;

  @media screen and (min-width: 1180px) {
    display: flex;
  }
`;

const Mobile = styled.div`
  display: none;

  @media screen and (max-width: 1179px) {
    display: block;
  }
`;

interface Props {
  padForPromotion?: boolean;
  sticky?: boolean;
}

const Header = ({ padForPromotion, sticky = true }: Props) => {
  const [displayShadow, setDisplayShadow] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      return setDisplayShadow(true);
    }
    setDisplayShadow(false);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    saveParamsToCookie();
  }, []);

  return (
    <Container
      css={css`
        top: 0;
      `}
      shadow={displayShadow}
      sticky={sticky}
    >
      <Logo to='/' aria-label="Link to Small Door's homepage." />
      <DesktopNavigation />
      <Mobile>
        <MobileNavigation />
      </Mobile>
    </Container>
  );
};

export default Header;
