import React from "react";
import { Link, useStaticQuery } from "gatsby";
import styled from "@emotion/styled";
import { BREAKPOINTS, COLORS } from "../../ui/variables";
import { graphql } from "gatsby";
import { ServiceNavigationQuery } from "../../../graphql-types";

const Container = styled.nav<{ isActive: boolean }>`
  background: ${COLORS.WHITE};
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 99px;
  left: 0;
  right: 0;
  padding: 40px 100px 80px 100px;
  border-top: 2px solid ${COLORS.LIGHT_GRAY};
  box-shadow: 0px 5px 8px -1px rgba(0, 0, 0, 0.15);
  opacity: ${props => (props.isActive ? 1 : 0)};
  pointer-events: ${props => (props.isActive ? "auto" : "none")};
  transition: opacity 100ms ease;

  @media ${BREAKPOINTS.TABLET} {
    top: 79px;
    padding: 40px;
  }
`;

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  font-weight: 400;

  li {
    font-size: 20px;
    margin-bottom: 24px;
  }

  li:first-of-type {
    text-transform: uppercase;
    color: ${COLORS.DARK_GRAY};
    font-size: 14px;
    margin-bottom: 18px;
  }
`;

const DropDownLink = styled(Link)`
  text-decoration: none;
  color: ${COLORS.BLACK};

  &:hover {
    color: ${COLORS.DARK_GRAY};
  }
`;

interface Props {
  display: boolean;
}

const SolutionsDropdown = (props: Props) => {
  const {
    contentfulServicePage: data,
  }: ServiceNavigationQuery = useStaticQuery(graphql`
    query ServiceNavigation {
      contentfulServicePage {
        categories {
          serviceCategory
          services {
            urlSlug
            title
          }
        }
      }
    }
  `);

  const { categories } = data;

  return (
    <Container isActive={props.display}>
      {categories.map(category => (
        <DropDownList key={category.serviceCategory}>
          <li>{category.serviceCategory}</li>
          {category.services.map(service => (
            <li key={service.urlSlug}>
              <DropDownLink to={`/services/${service.urlSlug}`}>
                {service.title}
              </DropDownLink>
            </li>
          ))}
        </DropDownList>
      ))}
    </Container>
  );
};

export default SolutionsDropdown;
